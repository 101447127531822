// react
import React from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// application
import Currency from "./Currency";
// import Rating from "./Rating";
import { url } from "../../services/utils";
import { config } from "../../data/custom";

function ProductCardNew(props) {
    const {
        product,
        layout,
        // wishlistAddItem
    } = props;
    const containerClasses = classNames("product-card", {
        "product-card--layout--grid product-card--size--sm": layout === "grid-sm",
        "product-card--layout--grid product-card--size--nl": layout === "grid-nl",
        "product-card--layout--grid product-card--size--lg": layout === "grid-lg",
        "product-card--layout--list": layout === "list",
        "product-card--layout--horizontal": layout === "horizontal",
    });

    let badges = [];
    let image;
    let price;
    // let features;

    if (product.formerprice && product.formerprice > product.price) {
        badges.push(
            <div key="sale" className="product-card__badge product-card__badge--sale">
                Sale
            </div>
        );
    }
    // if (product.badges.includes("hot")) {
    //     badges.push(
    //         <div key="hot" className="product-card__badge product-card__badge--hot">
    //             Hot
    //         </div>
    //     );
    // }
    // if (product.badges.includes("new")) {
    //     badges.push(
    //         <div key="new" className="product-card__badge product-card__badge--new">
    //             New
    //         </div>
    //     );
    // }

    badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;

    if (product.productimage) {
        image = (
            <div className="product-card__image product-image">
                <Link to={url.product(product)} className="product-image__body">
                    <img
                        loading="lazy"
                        className="product-image__img"
                        src={`${config.apiImageUrl}/medium/${product.productimage}`}
                        alt={product.productimageAlt ? product.productimageAlt : product.productname}
                        width="400px"
                        height="400px"
                    />
                </Link>
            </div>
        );
    } else {
        image = (
            <div className="product-card__image product-image">
                <Link to={url.product(product)} className="product-image__body">
                    <img
                        loading="lazy"
                        className="product-image__img"
                        src={`${config.apiImageUrl}/medium/images/no-image.png`}
                        alt={product.productimageAlt ? product.productimageAlt : product.productname}
                        width="400px"
                        height="400px"
                    />
                </Link>
            </div>
        );
    }

    if (product.formerprice && product.formerprice > product.price) {
        price = (
            <div className="product-card__prices">
                <span className="product-card__new-price">
                    <Currency value={product.price} />
                </span>{" "}
                <span className="product-card__old-price">
                    <Currency value={product.formerprice} />
                </span>
            </div>
        );
    } else {
        price = (
            <div className="product-card__prices">
                <Currency value={product.price} />
            </div>
        );
    }

    // if (product.attributes && product.attributes.length) {
    //     features = (
    //         <ul className="product-card__features-list">
    //             {product.attributes
    //                 .filter((x) => x.featured)
    //                 .map((attribute, index) => (
    //                     <li key={index}>{`${attribute.name}: ${attribute.values.map((x) => x.name).join(", ")}`}</li>
    //                 ))}
    //         </ul>
    //     );
    // }

    return (
        <div className={containerClasses}>
            {badges}
            {image}
            <div className="product-card__info">
                <div className="product-card__name">
                    <Link to={url.product(product)}>{product.productname}</Link>
                </div>
                {/* <div className="product-card__rating">
                    <Rating value={product.rating} />
                    <div className=" product-card__rating-legend">{`${product.reviews} Reviews`}</div>
                </div>
                {features} */}
            </div>
            <div className="product-card__actions">
                {/* <div className="product-card__availability">
                    Availability:
                    <span className="text-success">In Stock</span>
                </div> */}
                {price}
            </div>
        </div>
    );
}

ProductCardNew.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(["grid-sm", "grid-nl", "grid-lg", "list", "horizontal"]),
};

export default ProductCardNew;
