// react
import React, { useState, useRef } from "react";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import classNames from "classnames";
import ReCAPTCHA from "react-google-recaptcha";

// application
import SocialLinks from "../shared/SocialLinks";
import { config, recapta_site_key } from "../../data/custom";
import { PlayStoreSVG, AppleStoreSVG } from "../../svg";

export default function FooterNewsletter({ webnewsletterintro }) {
    const recaptchaRef = useRef(null);
    const [send, setSend] = useState(false);
    const { handleSubmit, register, errors, reset } = useForm();
    const onSubmit = async (values) => {
        setSend(true);
        try {
            const recaptchaValue = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();
            values.recaptchaValue = recaptchaValue;
        } catch (error) {
            setSend(false);
            return toast.error("No internet connection found!");
        }
        fetch(`${config.apiUrl}/subscriptions`, {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    reset();
                    toast.success(`${data.data.email} subscribed!`);
                } else {
                    if (data.error[0] === "Duplicate field value entered") {
                        toast.dark(`${values.email} already registered`);
                        reset();
                    } else {
                        toast.error("An error occured!");
                    }
                }
                setSend(false);
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("No internet connection found!");
                } else if (error.message === "Unexpected token R in JSON at position 0") {
                    toast.error("Request limit reached, Try later!");
                } else {
                    toast.error("An error occured!");
                }
                setSend(false);
            });
    };
    return (
        <div className="site-footer__widget footer-newsletter">
            <h5 className="footer-newsletter__title">Newsletter</h5>
            <div className="footer-newsletter__text">{webnewsletterintro}</div>
            <ReCAPTCHA sitekey={recapta_site_key} size="invisible" ref={recaptchaRef} />
            <form onSubmit={handleSubmit(onSubmit)} className="footer-newsletter__form">
                <label className="sr-only" htmlFor="footer-newsletter-address">
                    Email Address
                </label>
                <input
                    type="text"
                    className="footer-newsletter__form-input form-control"
                    id="footer-newsletter-address"
                    placeholder="Email Address..."
                    name="email"
                    ref={register({
                        required: "Email is required",
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid email address",
                        },
                        maxLength: {
                            value: 70,
                            message: "Email more than 70 characters",
                        },
                    })}
                />
                <button
                    type="submit"
                    className={classNames("footer-newsletter__form-button btn btn-primary", {
                        "btn-loading": send,
                    })}
                >
                    Subscribe
                </button>
            </form>
            {errors.email && <div className="text-danger">{errors.email.message}</div>}

            <div className="footer-newsletter__text footer-newsletter__text--social">Follow us on social media</div>

            <SocialLinks className="footer-newsletter__social-links mt-2" shape="circle" />

            <div className="footer-newsletter__text footer-newsletter__text--social">Download our mobile app!</div>
            <div className="flex app-store mt-2">
                <a
                    href="https://apps.apple.com/us/app/optracoy/id6446141459"
                    className="flex align-center store"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className="mr-2">
                        <AppleStoreSVG />
                    </div>
                    <div className="store-info">
                        <p>Download on</p>
                        <h3>Apple Store</h3>
                    </div>
                </a>
                <a
                    href="https://play.google.com/store/apps/details?id=com.optracoy.ecommercemobileapp"
                    className="flex align-center store"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className="mr-2">
                        <PlayStoreSVG />
                    </div>
                    <div className="store-info">
                        <p>Download on</p>
                        <h3>Play Store</h3>
                    </div>
                </a>
            </div>
        </div>
    );
}
