// react
import React, { useEffect } from "react";

// third-party
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// application
import StroykaSlick from "../shared/StroykaSlick";

// data stubs
import { setBrandImages } from "../../store/app";
import { config } from "../../data/custom";

const slickSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
            },
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
    ],
};

const BlockBrands = ({ brandImages, setBrandImages }) => {
    useEffect(() => {
        if (brandImages && !brandImages.length) {
            fetch(`${config.apiUrl}/brands?limit=24&select=brandimage,brandname`, {
                method: "GET",
                crossDomain: true,
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success === true) {
                        setBrandImages(data.data);
                    }
                })
                .catch((error) => {
                    if (error.message === "Failed to fetch") {
                    } else {
                    }
                });
        }
    }, [brandImages, setBrandImages]);
    const brandsList = brandImages.map((brand, index) => {
        return (
            <div key={index} className="block-brands__item">
                <Link to="/">
                    <img
                        src={brand.brandimage ? `${config.apiImageUrl}/${brand.brandimage}` : "images/nobrand.jpg"}
                        alt={brand.brandname}
                    />
                </Link>
            </div>
        );
    });
    return (
        <div className="block block-brands">
            <div className="container">
                <div className="block-brands__slider">
                    <StroykaSlick {...slickSettings}>{brandsList}</StroykaSlick>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    brandImages: state.app.brandImages,
});

const mapDispatchToProps = (dispatch) => ({
    setBrandImages: (brands) => dispatch(setBrandImages(brands)),
});
export default connect(mapStateToProps, mapDispatchToProps)(BlockBrands);
