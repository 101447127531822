// react
import React, { useEffect, useReducer } from "react";

// third-party
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga";

// application
import BlockLoader from "../blocks/BlockLoader";
import CategorySidebar from "./CategorySidebar";
import CategorySidebarItem from "./CategorySidebarItem";
import PageHeader from "../shared/PageHeader";
import ProductsViewNew from "./ProductsViewNew";
import WidgetFilters from "../widgets/WidgetFilters";
import { sidebarClose } from "../../store/sidebar";
import { setPriceFilter, setBrandCheck, setReload } from "../../store/app";

// data stubs
import theme from "../../data/theme";
import { url } from "../../services/utils";
import { config, limitStr, GATrackingId } from "../../data/custom";
import { setHideTopAlert } from "../../store/menu";

const initialState = {
    /**
     * Indicates that the products list is loading.
     */
    productsListIsLoading: true,
    productsIsLoadingMore: false,
    /**
     * Products list options.
     */
    pageNo: 1,
    nextpage: 0,
    count: 0,
    totalcount: 0,
    limit: 20,
    sort: "-createdAt",
    productsData: null,
    min: 0,
    max: 0,
    minValue: 0,
    maxValue: 0,
    brand: [],
    options: {},
    err_msg: [],
    /**
     * Products list filters.
     *
     * filters[FILTER_SLUG]: string - filter value.
     */
    filters: {},
};

function reducer(state, action) {
    switch (action.type) {
        case "FETCH_PRODUCTS_LIST":
            return { ...state, productsListIsLoading: action.payload };
        case "FETCH_MORE_PRODUCTS":
            return { ...state, productsIsLoadingMore: action.payload };
        case "FETCH_PRODUCTS_LIST_SUCCESS":
            return { ...state, productsListIsLoading: false, productsList: action.productsList };
        case "setnextpage":
            return {
                ...state,
                nextpage: action.payload,
            };
        case "setcount":
            return {
                ...state,
                count: action.payload,
            };
        case "settotalcount":
            return {
                ...state,
                totalcount: action.payload,
            };
        case "SET_MINVALUE":
            return { ...state, minValue: action.payload, min: action.payload };
        case "SET_MAXVALUE":
            return { ...state, maxValue: action.payload, max: action.payload };
        case "SET_LIMIT":
            return { ...state, limit: action.payload };
        case "SET_BRAND":
            return { ...state, brand: action.payload };
        case "SET_SORT":
            return { ...state, sort: action.payload };
        case "FETCH_PRODUCTS_DATA_SUCCESS":
            return {
                ...state,
                productsListIsLoading: false,
                productsData: action.productsData,
            };
        case "SET_ERR_MSG":
            return {
                ...state,
                err_msg: action.payload,
            };
        case "SET_FILTER_VALUE":
            const getVal = action.value.split("-");
            return {
                ...state,
                filters: { ...state.filters, [action.filter]: action.value },
                min: getVal[0],
                max: getVal[1],
            };
        case "RESET_FILTERS":
            return {
                ...state,
                min: state.minValue,
                max: state.maxValue,
                brand: state.brand.map((brd) => {
                    brd.checked = false;
                    return brd;
                }),
            };
        default:
            throw state;
    }
}

const ShopProducts = ({
    sidebarClose,
    priceFilter,
    brandCheck,
    setPriceFilter,
    setBrandCheck,
    columns,
    viewMode,
    sidebarPosition,
    search,
    searchPage,
    reload,
    setReload,
    onlineIncrement,
    setHideTopAlert,
}) => {
    let history = useHistory();
    let { slug, prdsid } = useParams();
    const offcanvas = columns === 3 ? "mobile" : "always";
    const [state, dispatch] = useReducer(reducer, initialState);
    let filter_price = "";
    let selected_brands = "";
    let cat = "";
    let tit = "";
    let subs = "";
    const prefix = () => {
        if (brandCheck && state.brand.length) {
            let i = 1;
            state.brand.map((brand) => {
                if (brand.checked) {
                    if (i === 1) {
                        selected_brands += `&m_brand=${brand.id}`;
                        i++;
                    } else {
                        selected_brands += `,${brand.id}`;
                    }
                }
                return brand;
            });
        }
        if (priceFilter) {
            let dprices = state.filters.price.split("-");
            filter_price = `&price[gte]=${dprices[0]}&price[lte]=${dprices[1]}`;
        }
        if (prdsid) {
            const eachId = prdsid.split("-");
            if (eachId[0] && eachId[0] !== "0") {
                cat = `?category=${eachId[0]}`;
            } else if (eachId[1] && eachId[1] !== "0") {
                tit = `?title=${eachId[1]}`;
            } else if (eachId[2] && eachId[2] !== "0") {
                subs = `?submenu=${eachId[2]}`;
            }
        }
    };
    // Load products.
    useEffect(() => {
        searchPage && !search && history.push("/");
        setHideTopAlert(false);
        let canceled = false;
        !searchPage && prefix();
        dispatch({ type: "FETCH_PRODUCTS_LIST", payload: true });
        dispatch({ type: "SET_ERR_MSG", payload: [] });
        let fetchUrl;
        searchPage
            ? (fetchUrl = `${config.apiUrl}/products?search=${search}&limit=${state.limit}&page=1`)
            : (fetchUrl = `${config.apiUrl}/products/filter${cat + tit + subs}&sort=${state.sort}&limit=${
                  state.limit
              }&page=1${filter_price + selected_brands}`);
        fetch(fetchUrl, {
            method: "GET",
            crossDomain: true,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    if (canceled) {
                        return;
                    }
                    if (data.pagination.next) {
                        dispatch({ type: "setnextpage", payload: data.pagination.next.page });
                    } else {
                        dispatch({ type: "setnextpage", payload: 0 });
                    }
                    dispatch({ type: "setcount", payload: data.count });
                    dispatch({ type: "settotalcount", payload: data.totalresults });
                    dispatch({ type: "FETCH_PRODUCTS_DATA_SUCCESS", productsData: data.data });
                    if (!searchPage && !priceFilter) {
                        dispatch({ type: "SET_MINVALUE", payload: data.min });
                        dispatch({ type: "SET_MAXVALUE", payload: data.max });
                    }
                    if (!searchPage && !brandCheck) {
                        dispatch({ type: "SET_BRAND", payload: data.brands });
                    }
                    GATrackingId && ReactGA.initialize(GATrackingId);
                    const track = window.location.pathname.split("/")[1];
                    GATrackingId && ReactGA.pageview(`/${track}`);
                } else {
                    dispatch({ type: "SET_ERR_MSG", payload: data.error });
                    dispatch({ type: "FETCH_PRODUCTS_DATA_SUCCESS", productsData: [] });
                }
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    dispatch({
                        type: "SET_ERR_MSG",
                        payload: [
                            "No internet connection found. Ensure the internet connection is on and reload the page. Thanks",
                        ],
                    });
                    toast.error("Internet connection Failure!");
                } else {
                    dispatch({ type: "SET_ERR_MSG", payload: ["An error occured. Try to reload the page!"] });
                    toast.error("An Error occured!");
                }
                dispatch({ type: "FETCH_PRODUCTS_DATA_SUCCESS", productsData: [] });
            });

        return () => {
            canceled = true;
        };
        // eslint-disable-next-line
    }, [dispatch, state.sort, state.limit, reload, search, prdsid]);
    const loadMore = () => {
        dispatch({ type: "FETCH_MORE_PRODUCTS", payload: true });
        !searchPage && prefix();
        let fetchUrl;
        searchPage
            ? (fetchUrl = `${config.apiUrl}/products?search=${search}&limit=${state.limit}&page=${state.nextpage}`)
            : (fetchUrl = `${config.apiUrl}/products/filter${cat + tit + subs}&sort=${state.sort}&limit=${
                  state.limit
              }&page=${state.nextpage}${filter_price + selected_brands}`);
        fetch(fetchUrl, {
            method: "GET",
            crossDomain: true,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    if (data.pagination.next) {
                        dispatch({
                            type: "setnextpage",
                            payload: data.pagination.next.page,
                        });
                    } else {
                        dispatch({
                            type: "setnextpage",
                            payload: 0,
                        });
                    }
                    dispatch({
                        type: "FETCH_PRODUCTS_DATA_SUCCESS",
                        productsData: [...state.productsData, ...data.data],
                    });
                    dispatch({
                        type: "setcount",
                        payload: state.count + data.count,
                    });
                    dispatch({ type: "FETCH_MORE_PRODUCTS", payload: false });
                } else {
                    dispatch({ type: "FETCH_MORE_PRODUCTS", payload: false });
                    toast.error("More product could not not be loaded. An error ocured!");
                }
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("No internet connection found!");
                } else {
                    toast.error("An error occured!");
                }
                dispatch({ type: "FETCH_MORE_PRODUCTS", payload: false });
            });
    };
    if (state.productsListIsLoading && !state.productsData) {
        return <BlockLoader />;
    }
    const breadcrumb = [{ title: "Home", url: url.home() }, { title: searchPage ? "Search Results" : slug }];
    let pageTitle = searchPage ? limitStr(search, 12) : "Products";
    let slugChange;
    if (slug) {
        slugChange = slug.split("_");
    }

    if (slugChange && slugChange.length) {
        pageTitle = "";
        let k = 1;
        slugChange.map((sc) => {
            if (k === 1) {
                pageTitle += sc;
                k++;
            } else {
                pageTitle += ` ${sc}`;
            }
            return sc;
        });
        slug = pageTitle;
    }
    const increasePrices = (productsData) => {
        if (onlineIncrement) {
            const newData = [...productsData].map((product) => {
                return {
                    ...product,
                    price: parseFloat((product.price + (product.price * onlineIncrement) / 100).toFixed(2)),
                };
            });
            return newData;
        }
        return productsData;
    };
    let content;
    const productsViewNew = (
        <ProductsViewNew
            searchPage={searchPage}
            isLoading={state.productsListIsLoading}
            isLoadingMore={state.productsIsLoadingMore}
            productsData={increasePrices(state.productsData)}
            errMsg={state.err_msg}
            //filters={state.filters}
            sort={state.sort}
            limit={state.limit}
            dispatch={dispatch}
            count={state.count}
            totalcount={state.totalcount}
            nextpage={state.nextpage}
            loadMore={loadMore}
            layout={viewMode}
            grid={`grid-${columns}-${columns > 3 ? "full" : "sidebar"}`}
            offcanvas={offcanvas}
        />
    );

    const sidebarComponent = (
        <CategorySidebar offcanvas={offcanvas}>
            <CategorySidebarItem>
                <WidgetFilters
                    title="Filters"
                    isLoading={state.productsListIsLoading}
                    offcanvas={offcanvas}
                    minValue={state.minValue}
                    min={state.min}
                    maxValue={state.maxValue}
                    max={state.max}
                    values={state.filters}
                    priceFilter={priceFilter}
                    setPriceFilter={setPriceFilter}
                    brandCheck={brandCheck}
                    setBrandCheck={setBrandCheck}
                    brand={state.brand}
                    sidebarClose={sidebarClose}
                    dispatch={dispatch}
                    setReload={setReload}
                    onlineIncrement={onlineIncrement}
                />
            </CategorySidebarItem>
        </CategorySidebar>
    );

    if (columns > 3) {
        content = (
            <div className="container">
                <div className="block">{productsViewNew}</div>
                {!searchPage && sidebarComponent}
            </div>
        );
    } else {
        const sidebar = <div className="shop-layout__sidebar">{sidebarComponent}</div>;

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === "start" && sidebar}
                    <div className="shop-layout__content">
                        <div className="block">{productsViewNew}</div>
                    </div>
                    {/* {sidebarPosition === "end" && sidebar} */}
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Optracoy — ${pageTitle || theme.name}`}</title>
            </Helmet>

            <PageHeader header={pageTitle} breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
};

ShopProducts.propTypes = {
    /**
     * Category slug.
     */
    categorySlug: PropTypes.string,
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(["grid", "grid-with-features", "list"]),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ShopProducts.defaultProps = {
    columns: 3,
    viewMode: "grid",
    sidebarPosition: "start",
    searchPage: false,
};

const mapStateToProps = (state) => ({
    sidebarState: state.sidebar,
    priceFilter: state.app.priceFilter,
    brandCheck: state.app.brandCheck,
    reload: state.app.reload,
    search: state.app.search,
    onlineIncrement: state.menu.info.onlineIncrement,
});

const mapDispatchToProps = {
    sidebarClose: () => sidebarClose(),
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
    setReload: (value) => setReload(value),
    setHideTopAlert: (boolean) => setHideTopAlert(boolean),
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopProducts);
