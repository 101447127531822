// react
import React, { useMemo, useEffect } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import ReactGA from "react-ga";

// application
import shopApi from "../../api/shop";
import { useProductTabs } from "../../services/hooks";
import { setPriceFilter, setBrandCheck, setSliderPictures } from "../../store/app";

// blocks
import BlockBanner from "../blocks/BlockBanner";
import BlockBrands from "../blocks/BlockBrands";
import BlockFeatures from "../blocks/BlockFeatures";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";
import BlockSlideShow from "../blocks/BlockSlideShow";

// data stubs
import theme from "../../data/theme";
import { config, GATrackingId } from "../../data/custom";
import { setHideTopAlert } from "../../store/menu";

const HomePageOne = ({
    priceFilter,
    brandCheck,
    setPriceFilter,
    setBrandCheck,
    sliderPictures,
    setSliderPictures,
    onlineIncrement,
    setHideTopAlert,
}) => {
    const loadItems = (data) => {
        return data.map((data, index) => {
            return {
                src: `${config.apiImageUrl}/big/${data.sliderimage}`,
                header: data.title,
                caption: data.text,
                altText: data.title,
                key: index++,
            };
        });
    };
    const noSlider = [
        {
            src: "images/noslider.jpg",
            altText: "No slider image",
            key: 0,
        },
    ];
    useEffect(() => {
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
        setHideTopAlert(false);
        if (sliderPictures && !sliderPictures.length) {
            fetch(`${config.apiUrl}/sliders?limit=5&publish=true`, {
                method: "GET",
                crossDomain: true,
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success === true) {
                        if (data.data.length) {
                            setSliderPictures(loadItems(data.data));
                        } else {
                            setSliderPictures(noSlider);
                        }
                    } else {
                        setSliderPictures(noSlider);
                    }
                })
                .catch((error) => {
                    if (error.message === "Failed to fetch") {
                    } else {
                    }
                });
        }
        GATrackingId && ReactGA.initialize(GATrackingId);
        GATrackingId && ReactGA.pageview(window.location.pathname);
    }, [
        priceFilter,
        brandCheck,
        setBrandCheck,
        setPriceFilter,
        sliderPictures,
        setSliderPictures,
        noSlider,
        setHideTopAlert,
    ]);
    const featuredProducts = useProductTabs(
        useMemo(
            () => [
                { id: 1, name: "All", categorySlug: undefined },
                // { id: 2, name: "Power Tools", categorySlug: "power-tools" },
                // { id: 3, name: "Hand Tools", categorySlug: "hand-tools" },
                // { id: 4, name: "Plumbing", categorySlug: "plumbing" },
            ],
            []
        ),
        (tab) => shopApi.getPopularProducts({ limit: 8, featuredproduct: true })
    );
    const salesProducts = useProductTabs(
        useMemo(
            () => [
                { id: 1, name: "All", categorySlug: undefined },
                // { id: 2, name: "Power Tools", categorySlug: "power-tools" },
                // { id: 3, name: "Hand Tools", categorySlug: "hand-tools" },
                // { id: 4, name: "Plumbing", categorySlug: "plumbing" },
            ],
            []
        ),
        (tab) => shopApi.getPopularProducts({ limit: 8, salesproduct: true })
    );
    const increasePrices = (productsData) => {
        if (onlineIncrement) {
            const newData = [...productsData].map((product) => {
                return {
                    ...product,
                    price: parseFloat((product.price + (product.price * onlineIncrement) / 100).toFixed(2)),
                };
            });
            return newData;
        }
        return productsData;
    };
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Home Page — ${theme.name}`}</title>
            </Helmet>

            {useMemo(
                () => (
                    <BlockSlideShow withDepartments sliderPictures={sliderPictures} />
                ),
                [sliderPictures]
            )}

            {useMemo(
                () => (
                    <BlockFeatures />
                ),
                []
            )}

            {useMemo(
                () =>
                    featuredProducts.data.data && featuredProducts.data.data.length ? (
                        <BlockProductsCarousel
                            title="Featured Products"
                            layout="grid-4"
                            products={increasePrices(featuredProducts.data.data)}
                            loading={featuredProducts.isLoading}
                            groups={featuredProducts.tabs}
                            onGroupClick={featuredProducts.handleTabChange}
                        />
                    ) : (
                        ""
                    ), // eslint-disable-next-line
                [featuredProducts, onlineIncrement]
            )}

            {useMemo(
                () => (
                    <BlockBanner />
                ),
                []
            )}

            {useMemo(
                () =>
                    salesProducts.data.data && salesProducts.data.data.length ? (
                        <BlockProductsCarousel
                            title="Sales Products"
                            layout="grid-4"
                            products={increasePrices(salesProducts.data.data)}
                            loading={salesProducts.isLoading}
                            groups={salesProducts.tabs}
                            onGroupClick={salesProducts.handleTabChange}
                        />
                    ) : (
                        ""
                    ), // eslint-disable-next-line
                [salesProducts]
            )}

            {useMemo(
                () => (
                    <BlockBrands />
                ),
                []
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    priceFilter: state.app.priceFilter,
    brandCheck: state.app.brandCheck,
    sliderPictures: state.app.sliderPictures,
    onlineIncrement: state.menu.info.onlineIncrement,
});

const mapDispatchToProps = {
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
    setSliderPictures: (brands) => setSliderPictures(brands),
    setHideTopAlert: (boolean) => setHideTopAlert(boolean),
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePageOne);
