// react
import React, { useState, useEffect, useRef } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link, useHistory, useParams, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import qs from "query-string";
import ReactGA from "react-ga";

// application
import { Check100Svg, ArrowRoundedRight6x9Svg } from "../../svg";
import { setPriceFilter, setBrandCheck } from "../../store/app";
import { confirmItemSaved } from "../../store/cart";
import { config, getCookie, GATrackingId } from "../../data/custom";
import ErrorLoading from "../errors/ErrorLoading";
import Print from "./Print";

// data stubs
import theme from "../../data/theme";
// blocks
import BlockLoader from "../blocks/BlockLoader";
import { setHideTopAlert } from "../../store/menu";

const ShopPageOrderSuccess = ({
    toreference,
    priceFilter,
    brandCheck,
    setPriceFilter,
    setBrandCheck,
    confirmItemSaved,
    customer,
    reload,
    info,
    setHideTopAlert,
}) => {
    const printRef = useRef();
    const { referenceno } = useParams();
    const [errMsg, seterrMsg] = useState([]);
    const [sale, setSale] = useState("");
    const [user, setUser] = useState("");
    const [del, setDel] = useState(false);
    const [Items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    let history = useHistory();
    useEffect(() => {
        const to = qs.parse(window.location.search);
        setHideTopAlert(false);
        toreference && toreference === referenceno && confirmItemSaved([]);
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
        setIsLoading(true);
        const cookies = getCookie("optc_token");
        fetch(`${config.apiUrl}/onlinesales/reference/${referenceno}`, {
            method: "GET",
            crossDomain: true,
            headers: {
                authorization: `Bearer ${JSON.parse(cookies)}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    setSale(data.data);
                    setUser(data.data.user);
                    setItems([...data.data.onlinebaskets]);
                    GATrackingId && ReactGA.initialize(GATrackingId);
                    const track = window.location.pathname.split("/")[1];
                    GATrackingId && ReactGA.pageview(`/${track}`);
                } else {
                    seterrMsg(data.error);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    seterrMsg(["No internet connection found!"]);
                } else {
                    seterrMsg(["An error occured!"]);
                }
                setIsLoading(false);
            });
        if (to.pay === "shipping") {
            setDel(true);
        }
        // eslint-disable-next-line
    }, [
        del,
        referenceno,
        toreference,
        brandCheck,
        priceFilter,
        setBrandCheck,
        setPriceFilter,
        confirmItemSaved,
        reload,
        customer,
        history,
    ]);
    if (isLoading) {
        return <BlockLoader />;
    }

    const accoutPageHeader = (
        <div className="page-header">
            <div className="page-header__container container">
                <div className="page-header__breadcrumb">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                                <ArrowRoundedRight6x9Svg className="breadcrumb-arrow" />
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/account/orders">Account History</Link>
                                <ArrowRoundedRight6x9Svg className="breadcrumb-arrow" />
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Order Receipt
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="page-header__title text-uppercase">
                    <h1>{sale.referenceno ? `Ref No: ${sale.referenceno}` : "Order Receipt"}</h1>
                </div>
            </div>
        </div>
    );
    const cookiesN = getCookie("optc_u_s_r");
    if (!customer && !cookiesN) {
        return <Redirect to="/login" />;
    }
    const customDate = (date) => {
        const newDate = new Date(date);
        return newDate.toLocaleDateString();
    };

    let sendItems = [];
    if (Items.length) {
        Items.map((item) => {
            const getInx = sendItems.findIndex((x) => x.pvid === item.productvariation._id);
            if (getInx === -1) {
                sendItems.push({
                    pvid: item.productvariation._id,
                    image: item.productvariation.product.productimage ? item.productvariation.product.productimage : "",
                    slug: item.productvariation.product.slug,
                    name: item.productname,
                    vartype: item.vartype,
                    varname: item.varname,
                    price: item.price,
                    count: item.count,
                    amount: item.amount,
                });
            } else {
                sendItems[getInx].count = sendItems[getInx].count + item.count;
                sendItems[getInx].amount = sendItems[getInx].amount + item.amount;
            }
            return item;
        });
    }

    let cusMsg;
    if (sale.status === "Pending") {
        cusMsg = (
            <>
                <div className="order-success__subtitle">Your order is in queue, please be patient with us.</div>
                <div className="card-divider mt-3" />
            </>
        );
    }
    let refMsg;
    if (toreference && toreference === referenceno) {
        refMsg = (
            <>
                <Check100Svg className="order-success__icon" />
                <h1 className="order-success__title">Thank you</h1>
                <div className="order-success__subtitle">Your order has been received</div>
                <div className="card-divider mt-3" />
            </>
        );
    } else {
        refMsg = cusMsg;
    }
    let content;
    if (errMsg && errMsg.length) {
        content = (
            <ErrorLoading
                title={"This order can not be shown!"}
                errMsg={errMsg}
                reloadNow={true}
                isLoading={isLoading}
            />
        );
    } else {
        content = (
            <div className="container">
                <div className="order-success__header">
                    {refMsg}
                    <div className="order-success__actions">
                        <Link to="/account/orders" className="btn btn-sm btn-dark mr-4 mb-2">
                            <i className="fa fa-arrow-left mr-2"></i>Order History
                        </Link>
                        <ReactToPrint
                            trigger={() => (
                                <button id="printInvoice" className="btn btn-sm btn-info mb-2">
                                    <i className="fa fa-print mr-2"></i>Download
                                </button>
                            )}
                            content={() => printRef.current}
                        />
                    </div>

                    {/* <div className="order-success__subtitle">
                        Your receipt is below. Click on the Print Receipt button above to print!
                    </div> */}
                </div>
                <div className="shadow rounded" id="invoice">
                    <Print
                        ref={printRef}
                        cart={sendItems}
                        sale={sale}
                        customDate={customDate}
                        user={user}
                        info={info}
                    />
                </div>
            </div>
        );
    }
    return (
        <div className="block order-success">
            <Helmet>
                <title>{`Order Receipt — ${theme.name}`}</title>
            </Helmet>
            {accoutPageHeader}
            {content}
        </div>
    );
};
const mapStateToProps = (state) => ({
    toreference: state.cart.toreference,
    priceFilter: state.app.priceFilter,
    brandCheck: state.app.brandCheck,
    customer: state.app.customer.name,
    reload: state.app.reload,
    info: state.menu.info,
});

const mapDispatchToProps = {
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
    confirmItemSaved: (items) => confirmItemSaved(items),
    setHideTopAlert: (boolean) => setHideTopAlert(boolean),
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageOrderSuccess);
