// react
import React, { useState, useEffect } from "react";

// third-party
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link, useHistory } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useForm } from "react-hook-form";
import ReactGA from "react-ga";
import classNames from "classnames";

// application
import Collapse from "../shared/Collapse";
import Currency from "../shared/Currency";
import PageHeader from "../shared/PageHeader";
import { Check9x7Svg, CheckPaySvg } from "../../svg";
import { setPriceFilter, setBrandCheck, loadU_S_R } from "../../store/app";
import { setTax, confirmItemSaved, setToReference, setCouponCode, setRemainingCredit } from "../../store/cart";
import ErrorLoading from "../errors/ErrorLoading";

// data stubs
import paymentss from "../../data/shopPayments";
import states from "../../data/states";
import theme from "../../data/theme";
import {
    config,
    getCookie,
    deleteCookie,
    paystackKey,
    flutterwaveKey,
    GATrackingId,
    flutterwaveLogo,
} from "../../data/custom";
import { setHideTopAlert } from "../../store/menu";

// blocks
import BlockLoader from "../blocks/BlockLoader";

const ShopPageCheckout = ({
    cart,
    priceFilter,
    brandCheck,
    loadU_S_R,
    setPriceFilter,
    setBrandCheck,
    customer,
    reload,
    confirmItemSaved,
    setTax,
    setCouponCode,
    setRemainingCredit,
    setToReference,
    currentCurrency,
    setHideTopAlert,
}) => {
    const [payment, setPayment] = useState("pickup");
    const [altered, setAltered] = useState(false);
    const [errMsg, seterrMsg] = useState([]);
    const [user, setUser] = useState("");
    const [read, setRead] = useState(true);
    const [paymentGateway, setPaymentGateway] = useState();
    const [reference, setReference] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [copied, setCopied] = useState(false);
    const [copied2, setCopied2] = useState(false);
    const [sendPayment, setSendPayment] = useState(false);
    const [payOnline, setPayOnline] = useState(true);
    const [banks, setBanks] = useState([]);
    const [invoiceno, setInvoiceno] = useState("");

    const { handleSubmit, register, errors, reset } = useForm();
    let history = useHistory();

    useEffect(() => {
        const cookiesN = getCookie("optc_u_s_r");
        if (!customer && !cookiesN) {
            return history.push("login?return=checkout");
        } else {
            //if (cart.items.length) {
            setSendPayment(true);
            setHideTopAlert(true);
            setReference(new Date().getTime());
            setAltered(false);
            seterrMsg([]);
            setIsLoading(true);
            const cookies = getCookie("optc_token");
            const me = fetch(`${config.apiUrl}/auth/me`, {
                method: "GET",
                crossDomain: true,
                headers: {
                    authorization: `Bearer ${JSON.parse(cookies)}`,
                },
            });
            const ban = fetch(`${config.apiUrl}/banks/user`, {
                method: "GET",
                crossDomain: true,
                headers: {
                    authorization: `Bearer ${JSON.parse(cookies)}`,
                },
            });
            const con = fetch(`${config.apiUrl}/onlinesales/confirmcart`, {
                method: "POST",
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    cart: cart.items,
                }),
            });
            const inf = fetch(`${config.apiUrl}/infos?select=tax,allowOnlinePayment,paymentGateway`, {
                method: "GET",
                crossDomain: true,
            });
            Promise.all([me, con, inf, ban])
                .then(async ([aa, bb, cc, dd]) => {
                    const a = await aa.json();
                    const b = await bb.json();
                    const c = await cc.json();
                    const d = await dd.json();
                    return [a, b, c, d];
                })
                .then((data) => {
                    if (data[0].success === true) {
                        setUser(data[0].data);
                    } else {
                        deleteCookie("optc_u_s_r");
                        deleteCookie("optc_token");
                        loadU_S_R("");
                        seterrMsg(["An error occured!"]);
                    }

                    if (data[1].success === true) {
                        if (data[1].data.altered) {
                            confirmItemSaved(data[1].data.cart);
                            setAltered(true);
                        }
                    }

                    if (data[2].success === true) {
                        setTax(data[2].data[0].tax);
                        setPaymentGateway(data[2].data[0].paymentGateway);
                        !data[2].data[0].allowOnlinePayment && history.push("/cart");
                    } else {
                        seterrMsg(["An error occured!"]);
                    }

                    if (data[3].success === true) {
                        if (data[3].data) {
                            setBanks(data[3].data);
                        }
                    }

                    setIsLoading(false);
                    GATrackingId && ReactGA.initialize(GATrackingId);
                    GATrackingId && ReactGA.pageview(window.location.pathname);
                })
                .catch((error) => {
                    if (error.message === "Failed to fetch") {
                        seterrMsg(["No internet connection found!"]);
                    } else {
                        seterrMsg(["An error occured!"]);
                    }
                    setIsLoading(false);
                });
            // }
        }
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
        // eslint-disable-next-line
    }, [
        priceFilter,
        brandCheck,
        setPriceFilter,
        loadU_S_R,
        setBrandCheck,
        reload,
        //cart.items.length,
        customer,
        history,
        //cart.items,
        confirmItemSaved,
        setTax,
    ]);

    useEffect(() => {
        if (altered) {
            history.push("/cart");
        }
    }, [altered, history]);

    const onSubmit = async (data) => {
        if (!grandTotal) {
            handlePayWithCoupon(data, "shipment");
        } else {
            await payWithGateway(data, "shipment");
        }
    };

    const handleOnlinePayment = async () => {
        if (!grandTotal) {
            handlePayWithCoupon();
        } else {
            await payWithGateway();
        }
    };

    const priceFormater = (value) => {
        const symbol = "₦";
        const fixedPrice = Number(value).toFixed(2);
        const formattedPrice = symbol + fixedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return formattedPrice;
    };

    let discount = 0;
    let grandTotal = 0;

    if (cart.remainingCredit > cart.total) {
        discount = cart.total;
    } else {
        discount = cart.remainingCredit;
    }

    grandTotal = cart.total - discount;

    // let conFees;
    // if (cart.total <= 2462.49) {
    //     conFees = cart.total / 0.985 - cart.total;
    // } else if (cart.total > 2462.49 && cart.total < 124666) {
    //     conFees = (cart.total + 100) / 0.985 - cart.total;
    // } else {
    //     conFees = 2000;
    // }
    let value = "";
    if (cart) {
        cart.items &&
            cart.items.map((item) => {
                let varT = "";
                if (item.type && item.varname) {
                    varT = `(${item.type}: ${item.varname})`;
                }
                value += `${item.name + varT} x ${item.count} = ${currentCurrency + item.amount.toFixed(2)},\n`;
                return item;
            });
        cart.subtotal && (value += `SUBTOTAL = ${currentCurrency + cart.subtotal.toFixed(2)},\n`);

        cart.tax &&
            cart.tax > 0 &&
            (value += `TAX(${cart.tax}%) = ${currentCurrency + ((cart.subtotal * cart.tax) / 100).toFixed(2)},\n`);

        discount && discount > 0 && (value += `Coupon Discount = ${discount.toFixed(2)},\n`);

        //conFees && (value += `CONVENIENCE FEE  = ${currentCurrency + conFees.toFixed(2)}\n`);

        //cart.total && (value += `GRANDTOTAL = ${currentCurrency + (conFees + cart.total).toFixed(2)}\n`);
        cart.total && (value += `GRANDTOTAL = ${currentCurrency + grandTotal.toFixed(2)}\n`);
    }
    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };
    const handleCopy2 = () => {
        setCopied2(true);
        setTimeout(() => {
            setCopied2(false);
        }, 2000);
    };
    //const payAmount = parseInt((conFees + cart.total) * 100);
    const payAmount = parseInt(grandTotal * 100);
    const paystackConfig = {
        publicKey: paystackKey,
        email: user.email ? user.email : `${user.number}@noemail.com`,
        phone: user.number,
        amount: payAmount,
        currency: "NGN",
        reference,
    };
    const flutterwaveConfig = {
        public_key: flutterwaveKey,
        tx_ref: reference,
        amount: cart.total,
        currency: "NGN",
        payment_options: "card,mobilemoney,ussd",
        customer: {
            email: user.email ? user.email : `${user.number}@noemail.com`,
            phone_number: user.number,
            name: user.name,
        },
        customizations: {
            title: "Optracoy Payment",
            description: `Payment for ${cart.quantity > 1 ? "items" : "item"} in cart`,
            logo: flutterwaveLogo,
        },
    };
    // you can call this function anything
    const onSuccess = (response) => {
        if (!sendPayment) {
            return false;
        }
        // Implementation for whatever you want to do with response and after success call.
        const cookies = getCookie("optc_token");
        confirmItemSaved([]);
        let url;
        let options = {
            method: "GET",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${JSON.parse(cookies)}`,
            },
        };
        if (paymentGateway === "paystack") {
            url = `${config.apiUrl}/onlinesales/${paymentGateway}/${response.reference}`;
        } else if (paymentGateway === "flutterwave") {
            url = `${config.apiUrl}/onlinesales/${paymentGateway}/${response.transaction_id}`;
            options = { ...options, method: "PUT", body: JSON.stringify({ txRef: response.tx_ref }) };
        }
        fetch(url, options)
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    setSendPayment(false);
                    reset();
                    setToReference(data.data);
                    history.push(`order/${data.data}`);
                } else {
                    toast.error("An Error occured!");
                }
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("Internet connection Failure!");
                } else {
                    toast.error("An Error occured!");
                }
                //payWithGateway === "flutterwave" && closePaymentModal();
            });
        setReference(new Date().getTime());
    };

    // you can call this function anything
    const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        const cookies = getCookie("optc_token");
        let url;
        if (paymentGateway === "paystack") {
            url = `${config.apiUrl}/onlinesales/${paymentGateway}/${reference}`;
        } else if (paymentGateway === "flutterwave") {
            url = `${config.apiUrl}/onlinesales/${paymentGateway}/${reference}`;
        }
        fetch(url, {
            method: "DELETE",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${JSON.parse(cookies)}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    //
                } else {
                    toast.error("An Error occured!");
                }
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("Internet connection Failure!");
                } else {
                    toast.error("An Error occured!");
                }
            });
        setReference(new Date().getTime());
    };
    const handlePaystackPayment = usePaystackPayment(paystackConfig);
    const handleFlutterPayment = useFlutterwave(flutterwaveConfig);
    const payWithGateway = (deliveryInfo = {}, deliverytype = "self") => {
        const cookies = getCookie("optc_token");
        fetch(`${config.apiUrl}/onlinesales/paywithgateway`, {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${JSON.parse(cookies)}`,
            },
            body: JSON.stringify({
                cart: cart.items,
                reference,
                deliveryInfo,
                deliverytype,
                couponCode: cart.couponCode,
                discount,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    if (data.data.altered) {
                        confirmItemSaved(data.data.cart);
                        if (data.data.couponError) {
                            toast.error(data.data.couponError);
                            setCouponCode("");
                            setRemainingCredit(null);
                        }
                        setAltered(true);
                    } else {
                        paymentGateway === "paystack" && handlePaystackPayment(onSuccess, onClose);
                        paymentGateway === "flutterwave" &&
                            handleFlutterPayment({
                                callback: (response) => {
                                    onSuccess(response);
                                    closePaymentModal();
                                    // this will close the modal programmatically
                                },
                                onClose: () => {
                                    onClose();
                                },
                            });
                    }
                } else {
                    toast.error("An Error occured!");
                }
                setReference(new Date().getTime());
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("Internet connection Failure!");
                } else {
                    toast.error("An Error occured!");
                }
                setReference(new Date().getTime());
            });
    };

    const handlePayWithCoupon = async (deliveryInfo = {}, deliverytype = "self") => {
        const cookies = getCookie("optc_token");
        fetch(`${config.apiUrl}/onlinesales/paywithcoupon`, {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${JSON.parse(cookies)}`,
            },
            body: JSON.stringify({
                cart: cart.items,
                reference,
                deliveryInfo,
                deliverytype,
                couponCode: cart.couponCode,
                discount,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    if (data.data.altered) {
                        confirmItemSaved(data.data.cart);
                        if (data.data.couponError) {
                            toast.error(data.data.couponError);
                            setCouponCode("");
                            setRemainingCredit(null);
                        }
                        setAltered(true);
                    } else {
                        confirmItemSaved([]);
                        setSendPayment(false);
                        reset();
                        setToReference(data.data);
                        history.push(`order/${data.data}`);
                    }
                } else {
                    toast.error("An Error occured!");
                }
                setReference(new Date().getTime());
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("Internet connection Failure!");
                } else {
                    toast.error("An Error occured!");
                }
                setReference(new Date().getTime());
            });
    };

    const sendOrder = async () => {
        const cookies = getCookie("optc_token");
        fetch(`${config.apiUrl}/onlineorders/send`, {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${JSON.parse(cookies)}`,
            },
            body: JSON.stringify({
                cart: cart.items,
                invoiceno: new Date().getTime(),
                couponCode: cart.couponCode,
                discount,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    setInvoiceno(data.data.invoiceno);
                    toast.success(`Your order has been sent with invoice ${data.data.invoiceno}`);
                } else {
                    toast.error("An Error occured!");
                }
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("Internet connection Failure!");
                } else {
                    toast.error("An Error occured!");
                }
            })
            .finally(() => {
                setReference(new Date().getTime());
            });
    };

    if (isLoading) {
        return <BlockLoader />;
    }

    const handleSetPayOnline = (boolean) => {
        setInvoiceno("");
        setPayOnline(boolean);
    };

    const handlePaymentChange = (event) => {
        if (event.target.checked) {
            setPayment(event.target.value);
        }
    };
    const changeRead = () => {
        setRead(!read);
    };
    let placeOrder;
    if (payOnline) {
        placeOrder = (
            <>
                <button
                    disabled={read ? false : true}
                    onClick={() => (payment === "pickup" ? handleOnlinePayment() : handleSubmit(onSubmit))}
                    type="button"
                    className="btn btn-primary btn-xl btn-block"
                >
                    Pay Now
                </button>{" "}
            </>
        );
    } else {
        placeOrder = (
            <button
                onClick={sendOrder}
                disabled={read ? false : true}
                type="button"
                className="btn btn-primary btn-xl btn-block"
            >
                Send Your Order
            </button>
        );
    }
    const renderTotals = () => {
        // const convenience = (
        //     <tr>
        //         <th>Convenience fees</th>
        //         <td>
        //             <Currency value={conFees} />
        //         </td>
        //     </tr>
        // );
        let renderTax;
        let renderDiscount;

        if (cart.tax) {
            renderTax = (
                <tr>
                    <th>{`Tax (${cart.tax}%)`}</th>
                    <td>
                        <Currency value={cart.total - cart.subtotal} />
                    </td>
                </tr>
            );
        }

        if (discount) {
            renderDiscount = (
                <tr>
                    <th>{`Coupon Discount`}</th>
                    <td>
                        <Currency value={discount} />
                    </td>
                </tr>
            );
        }

        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    <tr>
                        <th>Subtotal</th>
                        <td>
                            <Currency value={cart.subtotal} />
                        </td>
                    </tr>
                    {renderTax}
                    {renderDiscount}
                    {/* {convenience} */}
                </tbody>
            </React.Fragment>
        );
    };

    const renderCart = () => {
        const items = cart.items.map((item) => (
            <tr key={item.pvid}>
                <td>
                    <span>{item.name}</span>
                    {item.type ? <small>{` (${item.type} : ${item.varname})`}</small> : ""}
                    <span> × {item.count}</span>
                </td>
                <td>
                    <Currency value={item.amount} />
                </td>
            </tr>
        ));

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                    <tr>
                        <th>Product</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">{items}</tbody>
                {renderTotals()}
                <tfoot className="checkout__totals-footer">
                    <tr>
                        <th>Grand Total</th>
                        <td>
                            {/* <Currency value={cart.total + conFees} /> */}
                            <Currency value={grandTotal} />
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    };

    const renderPaymentsList = () => {
        const currentPayment = payment;

        const payments = paymentss.map((payment) => {
            let deliveryForm = "";
            if (payment.key !== "pickup") {
                deliveryForm = (
                    <>
                        <p className="mt-2">
                            <b>Please enter your delivery details below</b>
                        </p>
                        <div className="form-group mt-2 mb-2">
                            <label htmlFor="name">
                                Delivery contact Name<sup className="text-danger"> *</sup>
                            </label>
                            <input
                                id="name"
                                type="text"
                                className="form-control"
                                name="name"
                                defaultValue={user.name}
                                ref={register({
                                    required: "Delivery name is required",
                                    maxLength: {
                                        value: 100,
                                        message: "Name can not be more than 100 characters",
                                    },
                                })}
                            />
                            {errors.name && <p className="text-danger">{errors.name.message}</p>}
                        </div>
                        <div className="form-group mt-2 mb-2">
                            <label htmlFor="number">
                                Delivery contact Number<sup className="text-danger"> *</sup>
                            </label>
                            <input
                                id="number"
                                type="text"
                                className="form-control"
                                placeholder="e.g 08012345678"
                                name="number"
                                ref={register({
                                    required: "Phone number for delivery is required",
                                    minLength: {
                                        value: 4,
                                        message: "Phone number is too short",
                                    },
                                    maxLength: {
                                        value: 20,
                                        message: "Phone number is too long",
                                    },
                                    pattern: {
                                        value: /^[0-9]*$/,
                                        message: "Invalid phone number",
                                    },
                                })}
                            />
                            {errors.number && <p className="text-danger">{errors.number.message}</p>}
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="address">
                                Delivery address<sup className="text-danger"> *</sup>
                            </label>
                            <textarea
                                id="address"
                                type="text"
                                className="form-control"
                                name="address"
                                ref={register({
                                    required: "Delivery address is required",
                                    maxLength: {
                                        value: 100,
                                        message: "Delivery address should not be more than 100 characters",
                                    },
                                })}
                            />
                            {errors.address && <p className="text-danger">{errors.address.message}</p>}
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="city">
                                Delivery City/Town<sup className="text-danger"> *</sup>
                            </label>
                            <input
                                id="city"
                                type="text"
                                className="form-control"
                                name="city"
                                ref={register({
                                    required: "Delivery city or town is required",
                                })}
                            />
                            {errors.city && <p className="text-danger">{errors.city.message}</p>}
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="states">
                                State<sup className="text-danger"> *</sup>
                            </label>
                            <select
                                id="states"
                                className="form-control"
                                name="states"
                                ref={register({
                                    required: "Please select a state",
                                })}
                            >
                                <option value="">-- Choose A State --</option>
                                {states.map((st, index) => (
                                    <option key={index} value={st}>
                                        {st}
                                    </option>
                                ))}
                            </select>
                            {errors.states && <p className="text-danger">{errors.states.message}</p>}
                        </div>
                    </>
                );
            }
            const renderPayment = ({ setItemRef, setContentRef }) => (
                <li className="payment-methods__item" ref={setItemRef}>
                    <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="checkout_payment_method"
                                    value={payment.key}
                                    checked={currentPayment === payment.key}
                                    onChange={handlePaymentChange}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="payment-methods__item-title">{payment.title}</span>
                    </label>
                    <div className="payment-methods__item-container" ref={setContentRef}>
                        <div className="payment-methods__item-description text-muted">
                            {payment.description}

                            {deliveryForm}
                        </div>
                    </div>
                </li>
            );

            return (
                <Collapse
                    key={payment.key}
                    open={currentPayment === payment.key}
                    toggleClass="payment-methods__item--active"
                    render={renderPayment}
                />
            );
        });

        return (
            <div className="payment-methods">
                <ul className="payment-methods__list">{payments}</ul>
            </div>
        );
    };

    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "Shopping Cart", url: "/cart" },
        { title: "Checkout", url: "" },
    ];
    // let showAltered = null;
    // if (altered) {
    //     showAltered = (
    //         <div className="mb-2">
    //             <div className="alert alert-warning">
    //                 Sorry, your cart has been updated in compliance to current product price and availabilty.{" "}
    //                 <Link to="/cart">Modify cart here</Link>
    //             </div>
    //         </div>
    //     );
    // }
    let content;
    if (errMsg && errMsg.length) {
        content = (
            <ErrorLoading
                title={"You can not proceed with the checkout!"}
                errMsg={errMsg}
                reloadNow={true}
                isLoading={isLoading}
            />
        );
    } else if (!cart.items.length) {
        content = (
            <div className="block block-empty">
                <div className="container">
                    <div className="block-empty__body">
                        <div className="block-empty__message">Your shopping cart is empty!</div>
                        <div className="block-empty__actions">
                            <Link to="/" className="btn btn-primary btn-sm">
                                Continue
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="checkout block">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="flex border mb-3">
                                <div
                                    className={classNames(
                                        "h-50px flex-1 flex justify-center align-center pointer border-right",
                                        {
                                            "bg-light": payOnline,
                                        }
                                    )}
                                    onClick={() => handleSetPayOnline(true)}
                                >
                                    {payOnline && <CheckPaySvg className="mr-3 pay-checked" />} Pay Online
                                </div>
                                <div
                                    className={classNames(
                                        "h-50px flex-1 flex justify-center align-center pointer border-right",
                                        {
                                            "bg-light": !payOnline,
                                        }
                                    )}
                                    onClick={() => handleSetPayOnline(false)}
                                >
                                    {!payOnline && <CheckPaySvg className="mr-3 pay-checked" />} Pay Offline
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 col-xl-6">
                            <div className="card mb-lg-0">
                                <div className="card-body">
                                    {payOnline ? (
                                        <>
                                            <h3 className="card-title">Delivery details</h3>
                                            <p> Thanks for your patronage, </p>
                                            <p>We provide two options for delivery.</p>
                                            <p>
                                                <b> 1. Self pickup</b>{" "}
                                            </p>
                                            <p>
                                                By picking this option, you agree to pick up your order at our office. A
                                                text message will be sent to you once your order is ready for pick up.
                                                You can also track you order status on your account dashboard once you
                                                are logged in
                                            </p>
                                            <p>
                                                <b> 2. Shipment</b>{" "}
                                            </p>
                                            <p>
                                                {" "}
                                                By picking this option, we will send you the delivery fee to your
                                                entered address after you have paid for your order. You can also chat
                                                with our customer support to know how much you will be pay for our
                                                delivery service
                                            </p>
                                            <p>
                                                <b>
                                                    You are expected to select an option before payment. Self pick up is
                                                    the default
                                                </b>
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <h3 className="card-title">Instructions</h3>
                                            <p> Pay offline in three simple steps: </p>
                                            <p> 1. Send your order to generate an invoice. </p>
                                            <p> 2. Click on chat with whatapp or call to send the invoice number. </p>
                                            <p>
                                                {" "}
                                                3. Pay {priceFormater(grandTotal)} into any of our bank acount and send
                                                a proof.{" "}
                                            </p>
                                            {banks.map((bank) => (
                                                <p key={bank._id}>
                                                    {bank.bankname} :- <b>{bank.bankaccount}</b>
                                                </p>
                                            ))}
                                            <p>
                                                <b>Please note, only payment validates order</b>
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 col-xl-6 mt-4 mt-lg-0">
                            <div className="card mb-0">
                                <div className="card-body">
                                    {invoiceno ? (
                                        <>
                                            <h3 className="card-title">Your Order has been sent!</h3>
                                            <div className="flex justify-between align-center">
                                                <p className="mb-0">
                                                    Invoice No: <b>{invoiceno}</b>
                                                </p>
                                                <div className="mb-2">
                                                    <CopyToClipboard onCopy={handleCopy2} text={invoiceno}>
                                                        <button
                                                            className={`btn btn-sm ${
                                                                copied2 ? "btn-success" : "btn-dark"
                                                            }`}
                                                        >
                                                            {copied2 ? "Copied!!!" : "Copy invoice"}
                                                        </button>
                                                    </CopyToClipboard>
                                                </div>
                                            </div>
                                            <p>Send us your invoice number on whatsapp or give us a call. Thanks</p>
                                            <div>
                                                <a
                                                    href={`https://wa.me/2349015960054?text=Hi%20Admin,%0AMy%20name%20is%20${customer.replace(
                                                        / /g,
                                                        "%20"
                                                    )}.%0AI%20have%20just%20sent%20an%20order%20with%20invoice%20number%20${invoiceno}.`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    title="Whatapp"
                                                    className="btn btn-success rounded-whatapp"
                                                >
                                                    <i className="fab fa-whatsapp" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <h3 className="card-title">Your Order</h3>
                                            {/* {showAltered} */}

                                            {renderCart()}
                                            <div className="mb-4">
                                                <span className="text-center text-underline">
                                                    <ins>
                                                        <Link to="/cart">Modify Cart</Link>
                                                    </ins>
                                                </span>
                                                <span className="">
                                                    <CopyToClipboard onCopy={handleCopy} text={value}>
                                                        <button
                                                            className={`float-right btn btn-sm ${
                                                                copied ? "btn-success" : "btn-dark"
                                                            }`}
                                                        >
                                                            {copied
                                                                ? "Copied to clipboard !!!"
                                                                : "Copy order to clipboard"}
                                                        </button>
                                                    </CopyToClipboard>
                                                </span>
                                            </div>
                                            <div className="checkout__agree form-group">
                                                <div className="form-check">
                                                    <span className="form-check-input input-check">
                                                        <span className="input-check__body">
                                                            <input
                                                                defaultChecked={read}
                                                                onChange={changeRead}
                                                                className="input-check__input"
                                                                type="checkbox"
                                                                id="checkout-terms"
                                                            />
                                                            <span className="input-check__box" />
                                                            <Check9x7Svg className="input-check__icon" />
                                                        </span>
                                                    </span>
                                                    <label className="form-check-label" htmlFor="checkout-terms">
                                                        I have read and agree to the website
                                                        <Link to="/terms"> terms and conditions</Link>*
                                                    </label>
                                                </div>
                                            </div>
                                            {payOnline ? renderPaymentsList() : null}

                                            {placeOrder}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Checkout — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Payment Checkout" breadcrumb={breadcrumb} />
            {content}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    cart: state.cart,
    priceFilter: state.app.priceFilter,
    brandCheck: state.app.brandCheck,
    customer: state.app.customer.name,
    reload: state.app.reload,
    currentCurrency: state.currency.symbol,
});

const mapDispatchToProps = {
    loadU_S_R: (customer) => loadU_S_R(customer),
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
    setTax: (tax) => setTax(tax),
    setToReference: (reference) => setToReference(reference),
    confirmItemSaved,
    setHideTopAlert: (boolean) => setHideTopAlert(boolean),
    setCouponCode: (couponCode) => setCouponCode(couponCode),
    setRemainingCredit: (remainingCredit) => setRemainingCredit(remainingCredit),
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout);
