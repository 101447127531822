// react
import React, { useState, useEffect } from "react";

// third-party
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { usePaystackPayment } from "react-paystack";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { toast } from "react-toastify";
import classNames from "classnames";

// application
import Pagination from "../shared/Pagination";
import Currency from "../shared/Currency";
import { setPriceFilter, setBrandCheck } from "../../store/app";

// data stubs
import theme from "../../data/theme";
// blocks
import BlockLoader from "../blocks/BlockLoader";
import { config, getCookie, limitStr, paystackKey, flutterwaveKey, flutterwaveLogo } from "../../data/custom";
import ErrorLoading from "../errors/ErrorLoading";

const AccountPageFactory = ({ priceFilter, brandCheck, setPriceFilter, setBrandCheck, reload, customer }) => {
    const [prev, setPrev] = useState(0);
    const [next, setNext] = useState(0);
    const [pages, setPages] = useState(1);
    const [pageNo, setPageNo] = useState(1);
    // const [conFees, setConFees] = useState(0);
    const [requests, setRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [load, setLoad] = useState(false);
    const [oneReq, setOneReq] = useState("");
    const [errMsg, seterrMsg] = useState([]);
    const [paymentGateway, setPaymentGateway] = useState();
    const payAmount = oneReq.cost ? parseInt(oneReq.cost * 100) : 0;
    let history = useHistory();
    const paystackConfig = {
        publicKey: paystackKey,
        email: customer.email ? customer.email : `${customer.number}@noemail.com`,
        phone: customer.number,
        amount: payAmount,
        currency: "NGN",
        reference: new Date().getTime(),
    };
    const flutterwaveConfig = {
        public_key: flutterwaveKey,
        tx_ref: new Date().getTime(),
        amount: oneReq.cost,
        currency: "NGN",
        payment_options: "card,mobilemoney,ussd",
        customer: {
            email: customer.email ? customer.email : `${customer.number}@noemail.com`,
            phone_number: customer.number,
            name: customer.name,
        },
        customizations: {
            title: "Optracoy Payment",
            description: `Payment for factory request`,
            logo: flutterwaveLogo,
        },
    };
    const handlePaystackPayment = usePaystackPayment(paystackConfig);
    const handleFlutterPayment = useFlutterwave(flutterwaveConfig);
    useEffect(() => {
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
        seterrMsg([]);
        setIsLoading(true);
        const cookies = getCookie("optc_token");

        const inf = fetch(`${config.apiUrl}/infos?select=tax,allowOnlinePayment,paymentGateway`, {
            method: "GET",
            crossDomain: true,
        });
        const getFac = fetch(`${config.apiUrl}/factories?sort=-createdAt&limit=10&page=${pageNo}`, {
            method: "GET",
            crossDomain: true,
            headers: {
                authorization: `Bearer ${JSON.parse(cookies)}`,
            },
        });
        Promise.all([getFac, inf])
            .then(async ([aa, bb]) => {
                const a = await aa.json();
                const b = await bb.json();
                return [a, b];
            })
            .then((data) => {
                if (data[0].success === true) {
                    data[0].pagination.prev ? setPrev(data[0].pagination.prev.page) : setPrev(0);
                    data[0].pagination.next ? setNext(data[0].pagination.next.page) : setNext(0);
                    if (data[0].totalpages === 0) {
                        setPageNo(1);
                    }
                    setPages(data[0].totalpages);
                    setRequests(data[0].data);
                    setIsLoading(false);
                } else {
                    seterrMsg(data[0].error);
                    setIsLoading(false);
                }
                if (data[1].success === true) {
                    setPaymentGateway(data[1].data[0].paymentGateway);
                    !data[1].data[0].allowOnlinePayment && history.push("/");
                } else {
                    seterrMsg(["An error occured!"]);
                }
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    seterrMsg(["Please check your internet connection and reload the page"]);
                } else {
                    seterrMsg(["An error occured!"]);
                }
                setIsLoading(false);
            });
    }, [brandCheck, history, priceFilter, setBrandCheck, setPriceFilter, reload, pageNo]);
    if (isLoading) {
        return <BlockLoader />;
    }

    const customDate = (date) => {
        const newDate = new Date(date);
        return newDate.toLocaleDateString();
    };
    const handleShow = (e, req) => {
        e.preventDefault();
        // if (req.cost <= 2462.49) {
        //     setConFees(req.cost / 0.985 - req.cost);
        // } else if (req.cost > 2462.49 && req.cost < 124666) {
        //     setConFees((req.cost + 100) / 0.985 - req.cost);
        // } else {
        //     setConFees(2000);
        // }
        setOneReq(req);
        setShow(true);
    };

    const onSuccess = (response) => {
        // Implementation for whatever you want to do with response and after success call.
        setLoad(true);
        const cookies = getCookie("optc_token");
        fetch(`${config.apiUrl}/factories/verifyfactorypayment/${oneReq._id}`, {
            method: "PUT",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${JSON.parse(cookies)}`,
            },
            body: JSON.stringify({
                reference: paymentGateway === "flutterwave" ? response.transaction_id : response.reference,
                paymentGateway,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    setOneReq({ ...oneReq, payment: "paid" });
                    const findInd = requests.findIndex((x) => x._id === oneReq._id);
                    if (findInd !== -1) {
                        const newItems = [
                            ...requests.slice(0, findInd),
                            {
                                ...requests[findInd],
                                payment: "paid",
                            },
                            ...requests.slice(findInd + 1),
                        ];
                        setRequests(newItems);
                    }
                } else {
                    toast.error("An Error occured!");
                }
                setLoad(false);
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("Internet connection Failure!");
                } else {
                    toast.error("An Error occured!");
                }
                setLoad(false);
            });
    };

    // you can call this function anything
    const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
    };

    const payWithGatway = () => {
        const cookies = getCookie("optc_token");
        fetch(`${config.apiUrl}/factories/client/${oneReq._id && oneReq._id}`, {
            method: "GET",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${JSON.parse(cookies)}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    if (data.data.cost === oneReq.cost) {
                        paymentGateway === "paystack" && handlePaystackPayment(onSuccess, onClose);
                        paymentGateway === "flutterwave" &&
                            handleFlutterPayment({
                                callback: (response) => {
                                    onSuccess(response);
                                    closePaymentModal();
                                    // this will close the modal programmatically
                                },
                                onClose: () => {
                                    onClose();
                                },
                            });
                    }
                } else {
                    toast.error("An Error occured!");
                }
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("Internet connection Failure!");
                } else {
                    toast.error("An Error occured!");
                }
            });
    };
    const hideShow = (e) => {
        e.preventDefault();
        setOneReq("");
        setShow(false);
    };
    let content;
    if (errMsg && errMsg.length) {
        content = (
            <ErrorLoading
                title={"This order can not be shown!"}
                errMsg={errMsg}
                reloadNow={true}
                isLoading={isLoading}
                removeBorder={true}
            />
        );
    } else if (requests.length) {
        const requestsList = requests.map((req) => (
            <tr key={req._id}>
                <td>
                    {req.requirement ? (
                        <Link to="/" onClick={(e) => handleShow(e, req)}>
                            {req.referenceno}
                        </Link>
                    ) : (
                        req.referenceno
                    )}
                </td>
                <td>
                    {req.cost ? (
                        <>
                            <Currency value={req.cost} />
                            {req.payment === "unpaid" && (
                                <Link to="/" onClick={(e) => handleShow(e, req)} className="ml-2">
                                    <span className="badge badge-danger" style={{ cursor: "pointer" }}>
                                        PAY
                                    </span>
                                </Link>
                            )}
                        </>
                    ) : (
                        "-"
                    )}
                </td>
                <td className="text-capitalize">{req.status}</td>
                <td>{customDate(req.createdAt)}</td>
                <td>
                    {req.requirement ? (
                        <>
                            {limitStr(req.requirement, 24)}
                            <Link to="/" onClick={(e) => handleShow(e, req)}>
                                (More)
                            </Link>
                        </>
                    ) : (
                        "-"
                    )}
                </td>
            </tr>
        ));
        let paginate;
        if (pages > 1) {
            paginate = (
                <>
                    <div className="card-divider" />
                    <div className="card-footer">
                        <Pagination prev={prev} next={next} pages={pages} pageNo={pageNo} setPageNo={setPageNo} />
                    </div>
                </>
            );
        }
        let headerContent;
        let innerContent;
        if (show) {
            headerContent = (
                <div className="order-header">
                    <div className="order-header__actions">
                        <Link to="/" className="btn btn-xs btn-secondary" onClick={hideShow}>
                            <i className="fa fa-arrow-left mr-2"></i>Back to list
                        </Link>
                    </div>
                    <h5 className="order-header__title">Reference No: {oneReq.referenceno}</h5>
                    <div className="order-header__subtitle">
                        Was placed on <mark className="order-header__date">{customDate(oneReq.createdAt)}. </mark>
                        {oneReq.cost && (
                            <>
                                {oneReq.payment === "paid" ? "Paid " : ""}cutting and leaping cost is{" "}
                                <mark className="order-header__status">
                                    <Currency value={oneReq.cost} />
                                </mark>
                            </>
                        )}
                    </div>
                </div>
                // <h5 className="factory-h5" onClick={hideShow}>
                //     <i className="fa fa-arrow-left mr-3" />
                //     Go Back
                // </h5>
            );
            innerContent = (
                <div className="card-body">
                    {oneReq.requirement && <p>{oneReq.requirement}</p>}
                    {oneReq.cost && oneReq.payment === "unpaid" && (
                        <div className="text-center mt-4">
                            <button
                                disabled={load ? true : false}
                                onClick={payWithGatway}
                                className={classNames("btn btn-sm btn-danger", {
                                    "btn-loading": load,
                                })}
                            >
                                Pay Now
                            </button>
                        </div>
                    )}
                </div>
            );
        } else {
            headerContent = (
                <div className="card-header">
                    <h5>Factory Request History</h5>
                </div>
            );
            innerContent = (
                <>
                    <div className="card-table">
                        <div className="table-responsive-sm">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Reference No</th>
                                        <th>Cutting And Leaping Cost</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                        <th>Requirement</th>
                                    </tr>
                                </thead>
                                <tbody>{requestsList}</tbody>
                            </table>
                        </div>
                    </div>
                    {paginate}
                </>
            );
        }
        content = (
            <>
                {headerContent}
                <div className="card-divider" />
                {innerContent}
            </>
        );
    } else {
        content = <ErrorLoading title={"You don't have any request history to show!"} removeBorder={true} />;
    }

    return (
        <div className="card">
            <Helmet>
                <title>{`Order History — ${theme.name}`}</title>
            </Helmet>
            {content}
        </div>
    );
};

const mapStateToProps = (state) => ({
    priceFilter: state.app.priceFilter,
    brandCheck: state.app.brandCheck,
    reload: state.app.reload,
    customer: state.app.customer,
});

const mapDispatchToProps = {
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageFactory);
